.backdrop{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.movie-page_dashboard{
    padding: 20px 15px 15px 15px;
    min-height: 450px;
}

.movie-page_dashboard-poster-section{
    display:flex;
    justify-content: center;
    align-items: flex-end;
}


.movie-page_dashboard-info{
    margin:15px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: rgba(var(--fg-color), 0.75);
}

.movie-page_title{
    font-size: 25px;
    font-weight: bold;
    color: var(--fg-color-rgb);
}

.movie-page_genres > span:nth-child(n+2)::before{
    content: " | ";
}
.cast-list{
    list-style-type:none;
    padding-left: 0px;
}
.cast-list > li{
    padding: 8px 0px 3px 0px;
    color: rgba(var(--fg-color), 0.8);
}
.profile-pic{
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
    background-color: rgba(var(--fg-color), 0.32);
}

#fullCastCrewModal .modal-content{
    background-color: var(--bg-color-2-rgb);
}

#fullCastCrewModal .modal-header{
    border-bottom: 1px solid var(--fine-line-color-rgba);
    color: rgba(var(--fg-color), 0.4);
}

#fullCastCrewModal .modal-body{
    padding-left:0px;
    padding-right: 0px;
    
    --scrollbarBG: rgba(var(--fg-color), 0.1);
    --thumbBG: rgba(var(--fg-color), 0.8);
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
#fullCastCrewModal .modal-body::-webkit-scrollbar{
    width: 8px;
}
#fullCastCrewModal .modal-body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}
#fullCastCrewModal .modal-body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
}

#fullCastCrewModal .modal-body li{
    padding: 8px 10px;
}
#fullCastCrewModal .modal-body li:nth-child(odd){
    background-color: rgba(var(--fg-color), 0.03);        
}

#fullCastCrewModal li{
    display:flex;
    align-items: center;
}
#fullCastCrewModal .cast-name{
    flex-grow: 1;
}
#fullCastCrewModal .cast-role{
    font-size: 14px;
    color: rgba(var(--fg-color), 0.6);
}
#fullCastCrewModal .list-heading{
    padding-left: 16px;
    padding-bottom: 8px;
    margin: 8px 16px 20px 16px;
    border-bottom: 1px solid var(--fine-line-color-rgba);
}
#fullCastCrewModal .modal-body ul{
    margin-bottom: 50px;
}
.more-cast-btn{
    background-color: transparent;
    border:none;
    color: rgba(var(--fg-color), 0.7);
    padding: 0px;
}
.more-cast-btn:focus, .more-cast-btn:hover{
    outline:none;
    color: rgba(var(--fg-color), 0.85);
} 
.video-list{
    height: 250px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: auto;  
    overflow-y: hidden;  
}
.video-list > div{
    margin-left: 20px;
}
@media only screen and (max-width: 576px){
    
    #fullCastCrewModal .list-heading{
        margin-left: 0px;
        margin-right: 0px;
        border-bottom: none;
    }
}
@media only screen and (min-width: 576px){
    .movie-page_dashboard{
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 15px;
    }
    .movie-page_dashboard-info{
        text-align: left;
        align-items: start;
        justify-content: flex-end;        
    }
}

@media only screen and (min-width: 768px){
    .movie-page_layout-grid{
        display: grid;
        grid-template-columns: 3fr 2fr;
    }
}

@media only screen and (min-width: 992px){
    #fullCastCrewModal .modal-body li{
        padding-left: 20px;
        padding-right: 20px;
    }
}

