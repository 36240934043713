.gallery-h-scrollarea{
    padding: 30px 10px 10px 10px;
    display:flex;
    overflow: hidden;
    border-left: 1px solid var(--fine-line-color-rgba);
    border-right: 1px solid var(--fine-line-color-rgba);
    overflow-x:auto;

    scroll-snap-type: x proximity;
    scroll-padding: 20px;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.gallery-h-scrollarea::-webkit-scrollbar {
    display: none;
  }

.gallery-h{
    display: grid;
    grid-template-columns: 35px auto 35px;
    /* background-color: rgba(228, 234, 255, 0.05); */
}
.gallery-h-scrollarea > *{
    margin: 0px 20px;
    scroll-snap-align: start;    
}

.gallery-h-scrollarea::before, .gallery-h-scrollarea::after{
    content: "";
    padding-left: 40px;  
}
.gallery-h-scrollarea::before{
    scroll-snap-align: start;
}
.gallery-h-scrollarea::after{
    scroll-snap-align: end;
}



.gallery-h > button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    border: none;
    background-color: transparent;
    background-image: linear-gradient(transparent, rgba(var(--fg-color), 0.2), transparent);
    transition: background-color 0.3s;
}
.gallery-h > button:focus{
    outline: none;
}
.gallery-h > button:hover{
    background-color: rgba(var(--fg-color), 0.1);
}
.gallery-left-arrow{
    width:0;
    height:0;
    border-right: 9px solid var(--fg-color-rgb);
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
}
.gallery-right-arrow{
    width:0;
    height:0;
    border-left: 9px solid var(--fg-color-rgb);
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
}
