
.trending-section-heading{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.trending-section-heading > *{
    margin: 8px;
}
.trending-section-heading > select{
    font-size: 18px;
    /* margin-left: 1em; */
    padding: 4px 8px; 
    border: none;
    
    color: var(--dark-color-rgb);
    background-color: var(--fg-color-rgb);
    transition: background-color 0.3s;
}
.trending-section-heading > select:hover{
    outline: none;
    background-color: var(--fg-color-highlight-rgb);   
}
.trending-section-heading > select:focus{
    outline: 1px solid var(--dark-color-rgb);  
}
.trending-section-heading > select > option{
    background-color: var(--fg-color-rgb);
    font-size: 14px;
}

.trending-section-heading .btn-group .btn{
    background-color: rgba(var(--fg-color), 0.1);
    color: var(--fg-color-rgb);
    border: 1px solid rgba(var(--fg-color),0.8);
}

.trending-section-heading .btn-group .btn:hover{
    background-color: rgba(var(--fg-color), 0.25);
}
.trending-section-heading .btn-group .btn.selected{    
    background-color: var(--fg-color-rgb);
    color: var(--dark-color-rgb);
    border:none;
}
.trending-section-heading .btn-group .btn.selected:hover{    
    background-color: var(--fg-color-highlight-rgb); 
}